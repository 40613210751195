// rem等比适配配置文件
// 基准大小
let rem;
// 设置 rem 函数
var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
function setRem(pwidth, prem) {
    var html = document.getElementsByTagName("html")[0];

    html.style.fontSize = oWidth / pwidth * prem + "px";

}
if (oWidth < 750) {
    setRem(750, 75)
} else {
    setRem(2000, 40)
}
// 初始化
// setRem(750,75)
// 改变窗口大小时重新设置 rem
window.onresize = function (e) {
    console.log('resize', e)
    if (e.currentTarget.innerWidth < 750) {
        setRem(750, 75)
    } else {
        setRem(2000, 40)
    }
}

