/* eslint-disable */
import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate";
import baseconfig from './modules/baseconfig.js';
// import { checkChannelUrl } from '@/api/user'

export default createStore({
  modules: {
    baseconfig
  },
  state: {
    show_toast: '',
    pageStatus: '0'
  },
  getters: {},
  mutations: {
    showToast(state, {message, time = 2000}) {
      state.show_toast = message
      const timer = setTimeout(() => {
        state.show_toast = ''
        clearTimeout(timer)
      }, time)
    },
    // setPageStatus(state, data) {
    //   state.pageStatus = data
    // }
  },
  actions: {
    // async checkUrl({commit}) {
    //   try {
    //     const res = await checkChannelUrl()
    //     if (res.data.result) {
    //       commit('setPageStatus', '2')
    //     } else {
    //       commit('setPageStatus', '1')
    //     }
    //   } catch {
    //   }
    // }
  },
  // plugins: [createPersistedState({
  //   storage: window.localStorage,
  //   paths: ['baseconfig']
  // })]
})
