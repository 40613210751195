import { createI18n } from "vue-i18n";

let messages = {};
let lang = "";

function mergeArrObj(list, key) {
  let obj = {};
  list[key].forEach((i) => {
    obj = Object.assign({}, obj, i);
  });
  return obj;
}

function getViews() {
  const itemize = {
    'zh-cn': [],
    'en-us': [],
    'ja-jp': [],
    'id-id': [],
    'ms-my': [],
    'ar': []
  };
  let views = require.context('../views', true, /\.js$/)
  views.keys().forEach(key => {
    const path = key.match(/([A-Za-z0-9-_]+)\./i);
    if (itemize[path[1]]) itemize[path[1]].push(views(key).default);
    else itemize[path[1]] = views(key);

  })
  for (const key in itemize) {
    messages[key] = {
      ...mergeArrObj(itemize, key),
    };
  }
  console.log('messages', messages)
  lang = localStorage.getItem('lang') || 'zh-cn';
}

getViews()

const i18n = createI18n({
  //这里选择false是为了配合使用vue3的composition API风格的新式API，
  //如果是vue2，就要选择true了
  legacy: false,
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  globalInjection: true, // 全局注入
  locale: lang,
  messages: messages
})

export default i18n
