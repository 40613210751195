import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VConsole from 'vconsole/dist/vconsole.min.js'
import rem from '@/utils/rem.js'
import { Form, Field, CellGroup,Button ,Checkbox, CheckboxGroup,DatePicker,Popup,Tab, Tabs    } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
// 注册i18n
import i18n from './i18n'
import '@/assets/scss/style.scss'
import LongPress from '@/utils/longPress'; // 导入自定义指令
// new VConsole()
createApp(App)
  .directive('longpress', LongPress)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Form)
  .use(Field)
  .use(CellGroup)
  .use(Button)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(DatePicker)
  .use(Popup)
  .use(Tab)
  .use(Tabs)
  .mount('#app')
