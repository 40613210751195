// import store from '@/store'

export default {
  namespaced: true,
  state: {
    appid: "JG61RS57",
    req_base: {
      app_id: "JG61RS57",
      c_type: "2",
      language: "zh-cn",
      vbox_id: "",
      vbox: "",
      user_id: localStorage.getItem('user_id') || '',
      sub_user_id: "",
      channel_id: "1824345314866233344"
    },
    token: localStorage.getItem('token') || '',
    mobileOperatingSystem: "",
    nick_name: ""
  },
  getters: {},
  mutations: {
    setMobileOperatingSystem(state, data) {
      state.mobileOperatingSystem = data
    },
    set_userinfo(state, data) {
      state.req_base.user_id = data.user_id || ''
      state.token = data.token || ''
      localStorage.setItem('token', data.token || '')
      localStorage.setItem('user_id', data.user_id || '')
    },
    set_channel_id(state, data) {
      state.req_base.channel_id = data
    }
  },
  actions: {}
}
