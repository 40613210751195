import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import YhLoading from "@/components/yh-loading";
import Toast from '@/components/Toast';
import PageClosed from "@/components/page-closed";
import NetworkError from "@/components/network-error";
import { computed, onMounted } from 'vue';
import store from '@/store';
import { getMobileOperatingSystem } from '@/utils/common.js';
export default {
  __name: 'App',
  setup(__props) {
    const pageStatus = computed(() => {
      return store.state.pageStatus;
    });
    onMounted(() => {
      store.commit('baseconfig/setMobileOperatingSystem', getMobileOperatingSystem());
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: 0
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: 0
        })) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_unref(Toast)), _createVNode(_unref(NetworkError))], 64);
    };
  }
};