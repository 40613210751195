export default {
  register: {
    subtitle: '填写信息',
    registerBtn: '注 册',
    name: '姓名',
    birthday: '生日',
    name_tip: '请输入姓名',
    age_tip: '请选择生日',
    sex: '性别',
    sex_tip: '请选择性别',
    grade:"年级",
    grade_tip:"请输入年级",
    tel: '联系电话',
    tel_tip: '请输入联系电话',
    email: '邮箱',
    email_tip: '请输入邮箱',
    account: '用户名',
    password: '密码',
    account_tip: '请输入用户名',
    password_tip: '请输入密码',
    confirmPassword: '确认密码',
    confirmPassword_tip: '请再次输入密码',
  }
};
