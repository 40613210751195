export default {
  login: {
    title: "AI角色模拟对话",
    subtitle: '欢迎登录',
    email: '邮箱',
    password: '密码',
    loginBtn: '登 录',
    email_tip: '请输入邮箱',
    password_tip: '请输入密码'
  }
};
