export default {
  login: {
    title: "AI CHARACTERS SIMULATE DIALOGUE",
    subtitle: 'WELCOME TO LOGIN',
    account: 'Account',
    password: 'Password',
    loginBtn: 'Login',

    account_tip: 'Please enter the account number',
    password_tip: 'Please enter your password'
  }
};
