import store from '@/store'

export const showToast = (message) => {
  store.commit('showToast', {message})
}
export function getMobileOperatingSystem() {
  console.log("执行getMobileOperatingSystem");
  // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
 
  // if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
  //   return 'iOS';
  // } else if (userAgent.match(/Android/i)) {
  //   return 'Android';
  // } else {
  //   return 'unknown';
  // }
  const u = navigator.userAgent;
  const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  let type = "";
  if (isAndroid) type = "Android";
  if (isiOS) type = "iOS";
  console.log("手机类型", type);
  return !type ? "windows" : type;
}