import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import(/* webpackChunkName: "orallanguage" */ '../views/newIndex/index.vue')
  },
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import(/* webpackChunkName: "orallanguage" */ '../views/index/index.vue')
  // },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "orallanguage" */ '../views/login/index.vue')
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "orallanguage" */ '../views/register/index.vue')
  },
  
  {
    path: "/transfer",
    name: "transfer",
    component: () => import(/* webpackChunkName: "orallanguage" */ '../views/transfer/index.vue')
  },
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   if ((!from.query.channelId || !from.query.language) && (!to.query.channelId || !to.query.language)) {
//     next()
//     store.commit('setPageStatus', '1')
//     localStorage.removeItem('lang')
//     store.commit('baseconfig/set_channel_id', '')
//     return
//   }
//   localStorage.setItem('lang', from.query.language || to.query.language || 'zh-cn')
//   store.commit('baseconfig/set_channel_id', from.query.channelId || to.query.channelId)

//   const token = store.state.baseconfig.token
//   const newQuery = {
//     ...to.query,
//     channelId: store.state.baseconfig.req_base.channel_id,
//     language: localStorage.getItem('lang'),
//   }

//   if (!token && to.path !== '/login') {
//     next({
//       path: '/login',
//       query: newQuery
//     })
//     return
//   }

//   if (!to.query.channelId || !to.query.language) {
//     next({
//       path: to.path,
//       query: newQuery
//     })
//     return
//   }

//   store.dispatch('checkUrl')
//   next()
// })

export default router
