import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-38ab6a4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "toast"
};
const _hoisted_2 = {
  class: "toast-info"
};
import { computed } from 'vue';
import store from '@/store';
export default {
  __name: 'Toast',
  setup(__props) {
    const show_message = computed(() => {
      return store.state.show_toast;
    });
    return (_ctx, _cache) => {
      return show_message.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(show_message.value), 1)])) : _createCommentVNode("", true);
    };
  }
};